import React from "react";
import image1 from "../img/images/kontoret.png";

const CaseSingle = () => {
    return (
        <>
            <div className="container mx-auto px-4 py-24">
                <div className="grid grid-cols-1 md:grid-cols-2 justify-center mt-10">
                    <div className="">
                        <h2 className="text-white font-title font-bold text-5xl z-20">STC</h2>
                        <div className="border-b-[12px] w-64 border-colorPeachy rounded"></div>
                        <div className="pt-10">
                            <p className="text-colorGreen">Kund STC</p>
                        </div>
                    </div>
                    <div></div>
                    <div className="mt-8">
                        <p className="text-white">
                            Gymkedjan STC Training Club gav oss ännu en gång förtroendet att fixa utseendet för deras
                            årliga julkampanj. Gymkedjan STC Training Club gav oss ännu en gång förtroendet att fixa
                            utseendet för deras årliga julkampanj.
                        </p>
                    </div>
                    <div className="md:col-span-2 py-10">
                        <div className="flex justify-center my-10">
                            <img className="rounded" src={image1}></img>
                        </div>
                    </div>
                    <div className="mt-4 text-white md:x-10">
                        <h5 className="font-body-extra">Bakgrund</h5>
                        <p>
                            Med över 100 träningsanläggningar från Ystad i söder till Åre i norr sysselsätter STC över 1
                            200 personer, och då pratar vi bara anställda. De har varit kunder till oss sedan tidernas
                            begynnelse, och vi är skyldiga till allt från logotyp och varumärke till trycksaker och en
                            rad andra saker. Varje december har STC en julkampanj få kan ha missat. Det är årets bästa
                            chans att klippa ett gymkort till dig eller till någon annan. Förutom att sälja gymkort
                            håller de även tävlingar och aktiviteter i sociala medier.
                        </p>
                    </div>
                    <div className="text-white mt-10 md:px-10">
                        <h5 className="font-body-extra">Uppdraget</h5>
                        <p>
                            Vårt uppdrag var att ta fram det visuella för kampanjen. Film för hemsidan och sociala
                            medier, bilder för tryck till digitala och analoga publiceringar. Vi låg även bakom
                            formgivningen av skärmreklam, flygblad, affischer, bussreklam och annonser för
                            tidningstryck. Vi skapade också innehåll för Instagram där STC höll lucköppningar från 1–24
                            december.
                        </p>
                    </div>
                </div>

                <div className="col col-span-2 md:col-span-2 flex justify-center pt-20">
                    <div class="columns-1 gap-5 sm:columns-2 sm:gap-4 md:columns-2 lg:columns-3 [&>img:not(:first-child)]:mt-4">
                        <img className="rounded-xl" src={image1} />
                        <img className="rounded-xl" src={image1} />
                        <img className="rounded-xl" src={image1} />
                        <img className="rounded-xl" src={image1} />
                        <img className="rounded-xl" src={image1} />
                        <img className="rounded-xl" src={image1} />
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 mt-10">
                    <div className="text-white mt-10  md:px-10">
                        <p>
                            Med över 100 träningsanläggningar från Ystad i söder till Åre i norr sysselsätter STC över 1
                            200 personer, och då pratar vi bara anställda. De har varit kunder till oss sedan tidernas
                            begynnelse, och vi är skyldiga till allt från logotyp och varumärke till trycksaker och en
                            rad andra saker. Varje december har STC en julkampanj få kan ha missat. Det är årets bästa
                            chans att klippa ett gymkort till dig eller till någon annan. Förutom att sälja gymkort
                            håller de även tävlingar och aktiviteter i sociala medier.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CaseSingle;
