import React from "react";
import image1 from "../img/images/kontoret.png";
const About = () => {
    return (
        <>
            <div className="container mx-auto px-4 py-20 mb-24">
                <div className="">
                    <h2 className="text-white font-title font-bold text-5xl z-20">Om oss</h2>
                    <div className="border-b-[12px] w-64 border-colorPeachy rounded mb-6"></div>
                </div>

                <div className="grid lg:grid-cols-2 lg:grid-rows-1 gap-10">
                    <p className="text-white">
                        Välkommen till Peach - din lokala reklambyrå med globala ambitioner! Trots vår lilla ort strävar
                        vi efter stora drömmar. Varje morgon fylls vårt kreativa kontor med designers, contentskapare
                        och kodexperter som tillsammans skapar minnesvärda kampanjer med fingertoppskänsla för trender
                        och teknik. Upptäck våra nöjda kunder nedan och ta din marknadsföring till nästa nivå genom att
                        ringa, mejla eller besöka oss. Välkommen till Peach - där små drömmar blir stora framgångar!
                    </p>

                    <div className="col col-span-2 md:col-span-2 flex justify-center mt-10">
                        <div class="columns-1 gap-5 sm:columns-2 sm:gap-4 md:columns-2 lg:columns-3 [&>img:not(:first-child)]:mt-4">
                            <img className="rounded-xl" src={image1} />
                            <img className="rounded-xl" src={image1} />
                            <img className="rounded-xl" src={image1} />
                            <img className="rounded-xl" src={image1} />
                            <img className="rounded-xl" src={image1} />
                            <img className="rounded-xl" src={image1} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;
