import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Case = () => {
    const [cases, setCases] = useState([]);

    useEffect(() => {
        // Make a request for data
        axios
            .get("https://api.gopeach.se/post/all")
            .then(function (response) {
                // Update the cases state with the data
                setCases(response.data);
            })
            .catch(function (error) {
                // Handle error
                console.log(error);
            });
    }, []); // Empty dependency array ensures the effect runs once after the initial render

    console.log(cases);

    return (
        <>
            <div className="container mx-auto px-4 py-32">
                <div className="">
                    <h2 className="text-white font-title font-bold text-4xl z-20">Cases</h2>
                    <div className="border-b-[8px] w-52 border-colorPeachy rounded mb-6"></div>
                </div>
                <div className="grid sm:grid-cols-2 sm:grid-rows-2 gap-4 mt-5">
                    {cases.map((item, index) => (
                        <Link to="case/:slug">
                            <div key={index} className="relative">
                                <img src={item.featuredMedia} alt={item.title} className="w-full rounded-xl" />
                                <div className="absolute z-10 bottom-0 bg-secondaryBG px-3 py-2 rounded-bl-xl rounded-tr-xl">
                                    <p className="text-sm font-semibold text-white">{item.title}</p>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Case;
