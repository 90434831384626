import React, { useEffect, useState } from "react";
import logoColor from "../../img/logo/logo.svg";
import logoVit from "../../img/logo/logo-white.svg";
import Menu from "../Header/Menu";
import { Sling as Hamburger } from "hamburger-react";
import { useLocation } from "react-router-dom";

const Header = () => {
    const [headerBackground, setHeaderBackground] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [logoWhite, setLogoWhite] = useState(false);

    const location = useLocation();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        // Function to handle scroll event
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setHeaderBackground(true);
                setLogoWhite(true);
            } else {
                setHeaderBackground(false);
                setLogoWhite(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const headerClasses = `fixed top-0 left-0 w-full p-2 z-50 ${
        headerBackground ? "bg-colorGreen transition-all duration-300 ease-in-out" : ""
    }`;

    const isHomePage = location.pathname === "/";
    const logo = isHomePage ? logoColor : logoWhite ? logoColor : logoVit;

    return (
        <header className={headerClasses}>
            {isMenuOpen && <Menu isMenuOpen={isMenuOpen} onCloseMenu={() => setIsMenuOpen(false)} />}

            <div className="text-white text-2xl font-bold flex items-center justify-start px-2 align-middle">
                <img src={logo} alt="Logo" className="w-20 py-2" />
            </div>
            <div className="absolute top-[3px] right-[8px] text-colorPeachy cursor-pointer z-50 exit">
                <Hamburger size="25" rounded easing="ease-in" toggled={isMenuOpen} toggle={setIsMenuOpen} />
            </div>
        </header>
    );
};

export default Header;
