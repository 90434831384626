import React from "react";
import Hero from "../components/Hero";
import Case from "../components/Case";
import Logotypes from "../components/Logotypes";

const Home = () => {
    return (
        <div>
            <Hero />
            <Case />
            <Logotypes />
        </div>
    );
};

export default Home;
