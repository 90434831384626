import React from "react";
import logoSymbol from "../img/logo/logo-symbol.svg";
import instagram from "../img/icons/instagram.svg";
import facebook from "../img/icons/facebook.svg";

const Footer = () => {
    return (
        <>
            <div className="bg-colorGreen rounded-b-none flex justify-center py-20">
                <div className="text-center">
                    <img src={logoSymbol} alt="Logo" className="w-12 mx-auto mb-8" />
                    <div className="flex flex-col py-2 text-textDark text-xl md:text-2xl font-body-extra">
                        <span className="p-1">0303-37 32 24</span>
                        <span className="py-1">yay@gopeach.se</span>
                        <span className="py-5">
                            Hålstensvägen 22 <br></br>446 37 Älvängen
                        </span>

                        <h3 className="text-primaryBG mt-4 text-xl">Följ oss</h3>
                        <div className="flex justify-center mt-2">
                            <img className="mx-1" src={facebook} alt="Logo" />
                            <img className="mx-1" src={instagram} alt="Logo" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
