import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer";
import Home from "./views/Home";
import About from "./views/About";
import Cases from "./views/Cases";
import CaseSingle from "./views/CaseSingle";
import Contact from "./views/Contact";
import "./index.css";
import './scss/main.scss';

function App() {

	useEffect(() => {
		document.body.classList.add('bg-primaryBG' , 'overflow-x-hidden', 'font-body');
	  }, []);

	return (
		<>
		      <Header />
			<Routes>
				<Route
					path='/'
					element={<Home />}
				/>
				<Route
					path='/about'
					element={<About />}
				/>
				<Route
					path='/cases'
					element={<Cases />}
				/>
				<Route
					path='/case/:slug'
					element={<CaseSingle />}
				/>
				<Route
					path='/contact'
					element={<Contact />}
				/>
			</Routes>
			<Footer />
		</>
	);
}

export default App;
