import logo from "../img/logo/stclogo.png";
import logo1 from "../img/logo/stclogo.png";
import logo2 from "../img/logo/stclogo.png";
import logo3 from "../img/logo/stclogo.png";
import logo4 from "../img/logo/stclogo.png";
import logo5 from "../img/logo/stclogo.png";
import logo6 from "../img/logo/stclogo.png";
import logo7 from "../img/logo/stclogo.png";
import logo8 from "../img/logo/stclogo.png";

export const customerLogos = [logo, logo1, logo3, logo4, logo5, logo6, logo7, logo8];
