import React, { useState, useEffect } from "react";
import axios from "axios";

const Cases = () => {
    const [cases, setCases] = useState([]);

    useEffect(() => {
        // Make a request for data
        axios
            .get("https://api.gopeach.se/post/all")
            .then(function (response) {
                // Update the cases state with the data
                setCases(response.data);
            })
            .catch(function (error) {
                // Handle error
                console.log(error);
            });
    }, []); // Empty dependency array ensures the effect runs once after the initial render

    console.log(cases);

    return (
        <>
            <div className="container mx-auto px-4 py-20">
                <h2 className="text-white font-title font-bold text-5xl z-20">Cases</h2>
                <div className="border-b-[12px] w-64 border-colorPeachy rounded"></div>

                <div className="my-10">
                    <button className="border-2 rounded-full px-10 py-2 border-colorPeachy text-white hover:bg-colorPeachy transition-all mx-2 my-2">
                        Webb
                    </button>
                    <button className="border-2 rounded-full px-10 py-2 border-colorPeachy text-white hover:bg-colorPeachy transition-all mx-2 my-2">
                        Design
                    </button>
                    <button className="border-2 rounded-full px-10 py-2 border-colorPeachy text-white hover:bg-colorPeachy transition-all mx-2 my-2">
                        Foto
                    </button>
                    <button className="border-2 rounded-full px-10 py-2 border-colorPeachy text-white hover:bg-colorPeachy transition-all mx-2 my-2">
                        Film
                    </button>
                </div>

                <div className="grid sm:grid-cols-2 sm:grid-rows-2 gap-4 mt-5">
                    {cases.map((item, index) => (
                        <div key={index} className="relative">
                            <img src={item.featuredMedia} alt={item.title} className="w-full rounded-xl" />
                            <div className="absolute z-10 bottom-0 bg-secondaryBG px-10 py-3 rounded-bl-xl rounded-tr-xl">
                                <p className="text-lg font-semibold text-white">{item.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Cases;
