import React from "react";
import { Link } from "react-router-dom";
import logo from "../../img/logo/logo-standing.svg";
import logoSymbol from "../../img/logo/logo-symbol.svg";
import close from "../../img/icons/close.png";
import instagram from "../../img/icons/instagram.svg";
import facebook from "../../img/icons/facebook.svg";

const Menu = ({ isMenuOpen, onCloseMenu }) => {
    const closeMenu = () => {
        onCloseMenu();
    };

    return (
        <>
            <div
                className={`menu fixed top-0 left-0 w-screen h-screen bg-colorGreen z-50 transition-transform transform ${
                    isMenuOpen ? "translate-x-0" : "-translate-x-full"
                }`}
            >
                <div className="relative">
                    <div className="absolute inset-3 flex justify-center mt-2">
                        <img src={logo} alt="Logo" className="w-42 h-10" />
                    </div>
                </div>

                <div className="text-center flex justify-center h-screen">
                    <ul className="font-body-extra my-auto text-primaryBG text-3xl lg:text-4xl">
                        <li className="my-3">
                            <Link
                                to="/"
                                onClick={closeMenu}
                                className="relative block after:block after:content-[''] after:absolute after:h-[10px] after:lg:h-[15px] after:bg-colorYellow after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center after:bottom-2 after:lg:bottom-4 after:-z-50 after:rounded-full hover:text-colorPeachy transition-all"
                            >
                                Hem
                            </Link>
                        </li>
                        <li className="my-3">
                            <Link
                                to="/cases"
                                onClick={closeMenu}
                                className="relative block after:block after:content-[''] after:absolute after:h-[10px] after:lg:h-[15px] after:bg-colorYellow after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center after:bottom-2 after:lg:bottom-4 after:-z-50 after:rounded-full hover:text-colorPeachy transition-all"
                            >
                                Cases
                            </Link>
                        </li>
                        <li className="my-3">
                            <Link
                                to="/about"
                                onClick={closeMenu}
                                className="relative block after:block after:content-[''] after:absolute after:h-[10px] after:lg:h-[15px] after:bg-colorYellow after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center after:bottom-2 after:lg:bottom-4 after:-z-50 after:rounded-full hover:text-colorPeachy transition-all"
                            >
                                Om oss
                            </Link>
                        </li>
                        <li className="my-3">
                            <Link
                                to="/contact"
                                onClick={closeMenu}
                                className="relative block after:block after:content-[''] after:absolute after:h-[10px] after:lg:h-[15px] after:bg-colorYellow after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center after:bottom-2 after:lg:bottom-4 after:-z-50 after:rounded-full hover:text-colorPeachy transition-all"
                            >
                                Kontakt
                            </Link>
                        </li>
                    </ul>

                    <div className="absolute bottom-0 mb-5 font-body-extra">
                        <img src={logoSymbol} alt="Logo" className="w-28 h-6 mx-auto mb-2" />
                        <div></div>
                        <h3 className="text-primaryBG text-sm">0303-37 32 24</h3>
                        <h3 className="text-primaryBG text-sm">yay@gopeach.se</h3>
                        <div className="mt-3">
                            <h3 className="text-primaryBG text-sm">Följ oss</h3>
                            <div className="flex justify-center mt-2">
                                <img className="mx-1" src={facebook} alt="Logo" />
                                <img className="mx-1" src={instagram} alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Menu;
