import React, { useState, useEffect } from "react";
import axios from "axios";
import image1 from "../img/images/jonk.jpeg";

const Contact = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        // Make a request for data
        axios
            .get("https://api.gopeach.se/users/")
            .then(function (response) {
                // Update the users state with the data
                setUsers(response.data);
            })
            .catch(function (error) {
                // Handle error
                console.log(error);
            });
    }, []); // Empty dependency array ensures the effect runs once after the initial render

    console.log(users);

    return (
        <>
            <div className="container mx-auto px-4 py-24">
                <div className="mb-6">
                    <h2 className="text-white font-title font-bold text-5xl z-20">Kontakt</h2>
                    <div className="border-b-[12px] w-64 border-colorPeachy rounded"></div>
                </div>

                <div className="grid lg:grid-cols-2 lg:grid-rows-1 gap-10">
                    <p className="text-white text-2xl">
                        Bäst och lättast når du oss på <br></br>{" "}
                        <span className="text-colorPeachy">yay@gopeach.se</span> <br></br>
                        <span className="text-colorPeachy">0303-37 32 24</span>
                    </p>
                </div>

                <div className="grid grid-cols-1 xl:grid-cols-2 gap-20 mt-20 px-4">
                    {users.map((user, index) => (
                        <div className="text-center md:text-start justify-center md:flex align-middle" key={index}>
                            <img className="w-full rounded-full md:w-96 text-center" src={user.profileImg} alt="" />
                            <div className="text-white mt-6 lg:mt-none md:my-auto md:ps-5">
                                <p className="text-2xl font-body-extra">{user.name}</p>
                                <p className="my-2 italic font-body">Webbis</p>
                                <a href="tel:076 - 00 00 00" className="my-2 text-colorPeachy hover:underline">
                                    076 - 00 00 00
                                </a>
                                <br />
                                <a href={`mailto:${user.email}`} className="my-2 text-colorPeachy hover:underline">
                                    {user.email}
                                </a>
                                <div className="border-b-[5px] w-32 mx-auto my-10 border-colorGreen rounded -z-30"></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Contact;
