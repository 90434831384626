import React from "react";
import { customerLogos } from "../utils/CustomerLogos";

const Logotypes = () => {
    const logos = customerLogos;

    return (
        <>
            <div className="bg-secondaryBG">
                <div className="container mx-auto px-4 py-20">
                    <div className="relative">
                        <div className="">
                            <h2 className="text-white font-bold text-4xl lg:text-5xl relative z-20 font-title text-center">
                                Våra <span className="text-colorPeachy">kunder</span>
                            </h2>
                        </div>
                        <div className="border-b-[12px] w-56 absolute bottom-4 border-colorPeachy rounded -z-30"></div>
                    </div>
                    <div className="text-center pt-10">
                        <ul className="flex flex-row flex-wrap justify-center m-0 p-0 w-100">
                            {logos.map((logo, index) => (
                                <li key={`${index}`}>
                                    <img className="px-4 py-4 w-36 lg:w-48" src={`${logo}`} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Logotypes;
