import React, { useEffect, useState } from "react";
import peachlings from "../img/images/peaches-stamps.gif";

const Hero = () => {
    const [circlePosition, setCirclePosition] = useState(-200);
    const [circleRotation, setCircleRotation] = useState(0);
    const [circleXaxis, setCircleXaxis] = useState(0);

    // Function to handle the scroll event
    const handleScroll = () => {
        // Calculate the new position of the circle based on the scroll position
        const scrollY = window.scrollY;

        // You can adjust the factor to control the speed of the movement
        const newPosition = -200 - scrollY;

        // Calculate the rotation angle based on scroll position (adjust as needed)
        const rotationAngle = -scrollY * 0.2; // Adjust the factor to control the rotation speed

        const xPosition = scrollY * -10; // Adjust as needed

        // Update the circle position and rotation
        setCirclePosition(newPosition);
        setCircleRotation(rotationAngle);
        setCircleXaxis(xPosition);
    };

    useEffect(() => {
        // Add the scroll event listener when the component mounts
        window.addEventListener("scroll", handleScroll);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // Apply the circlePosition and circleRotation to the circle's style

    const circleStyle = {
        transform: `translateY(${circlePosition}px) translateX(${circleXaxis}px) rotate(${circleRotation}deg)`,
        transition: "transform 0.2s ease", // Adjust the transition duration as needed
    };

    return (
        <div className="overflow pt-20">
            <div className="text-white flex align-middle absolute top-60 px-20">
                <h1 className="-z-50 text-2xl">Tryck inte här! snek</h1>
            </div>
            <div
                className="bg-colorGreen rounded-full rounded-tl-none absolute top-42 z-0 right-10 sm:right-16 md:right-24 lg:right-64 xl:-left-96 h-circle w-circle sm:w-circle-sm sm:h-circle-sm md:w-circle-md md:h-circle-md lg:w-circle-lg lg:h-circle-lg xl:w-circle-xl xl:h-circle-xl 2xl:w-circle-2xl 2xl:h-circle-2xl"
                style={circleStyle} // Apply the circle's position
            ></div>
            <div
                style={circleStyle}
                className="z-20 container mx-auto px-4  sm:h-[20rem] md:h-[35rem] lg:h-[45rem] xl:h-[55rem] mt-48 md:mt-80"
            >
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-0 pt-5 xl:pt-32">
                    <div className="col-span-1">
                        <h1 className="text-textDark font-title text-4xl font-bold leading-[1]">
                            Hello <span className="text-colorPeachy mt-0">Peaches</span>
                        </h1>
                        <p className="text-textDark text-base leading-base pt-2 w-60 xl:w-80">
                            En kreativ webbyrå för fantastiska digitala lösningar.
                        </p>
                    </div>
                </div>
                <div className="flex justify-start lg:justify-start w-full">
                    <img className="w-64 md:w-96 fixed" src={peachlings}></img>
                </div>
            </div>
            {/* <div
                className="bg-secondaryBG rounded-full absolute
                top-10 left-40 -z-20 sm:-top-52 sm:right-16 md:-top-72 md:right-24 lg:-top-72 lg:r-64 xl:-top-10 xl:left-92 
                h-circle w-circle sm:w-circle-sm sm:h-circle-sm md:w-circle-md md:h-circle-md lg:w-circle-lg lg:h-circle-lg xl:w-circle-2xl xl:h-circle-2xl"
            ></div> */}
        </div>
    );
};

export default Hero;
